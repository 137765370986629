@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

/* #Robo{
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    justify-content: space-between;
    top: 0;
    left: 0;
    z-index: 1;
} */

#controls {
    width: 24vw;
    height: 24vw;
    margin: auto;
    background-color: cadetblue;
    /* position: relative; */
}

#controls div {
    width: 30%;
    position: absolute;
    background-color: yellowgreen;
}

#controls #up {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border: 2vw solid violet;
    border-top: 0;
    border-bottom: 4vw solid red;
}

#controls #down {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border: 2vw solid violet;
    border-bottom: 0;
    border-top: 4vw solid red;
}

#controls #right {
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 0;
    height: 0;
    border: 2vw solid violet;
    border-right: 0;
    border-left: 4vw solid red;
}

#controls #left {
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 0;
    height: 0;
    border: 2vw solid violet;
    border-left: 0;
    border-right: 4vw solid red;
}

#controls #close {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    background: red;
    color: white;
    text-align: center;
    line-height: 10vw;
}

/* robot control page fresh css  */
.row-center-even {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.robocontrol-container {
    width: 100%;
    min-height: 90vh;
    flex-direction: column;
    font-size: 16px;
}

.robocontrol-heading {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 40px;
    color: var(--primary-color);
    font-family: 'Rubik', sans-serif;
}

.robocontrol-data-field {
    width: 33%;
}

.data-input input {
    border: 0;
    background-color: aliceblue;
    padding: 8px 20px;
    border-radius: 20px;
    color: #012d70;
    font-size: 1.3rem;
    font-family: 'Rubik', sans-serif;
}

input:scope {
    border: 0;
}

.data-submit button {
    background-color: var(--primary-color);
    color: var(--supplementary-color);
    font-size: 1.3rem;
    border: 0;
    padding: 8px 30px;
    border-radius: 20px;
    font-family: 'Rubik', sans-serif;
}

.data-submit button:hover {
    color: var(--secondary-color);
    cursor: pointer;
    transform: scale(0.9);
}

.video-control-container {
    width: 100%;
    height: 100vh;
}

.video-control-meet {
    width: 100%;
    /* position: absolute; */
}

.video-control-meet iframe {
    border: 0;
    height: 98vh;
    aspect-ratio: 1920/1080;
    position: relative;
}

.video-control-control {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 150px;
    height: 150px;
    padding: 10px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    color: black;
    z-index: 999999999;
}

.video-control-control button {
    border: 0;
    background-color: transparent;
    color: black;
    font-size: 1.25rem;
    position: relative;
}

.video-control-control > button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video-control-control button:nth-child(2) {
    position: absolute;
    top: 20%;
    /* transform: translate(-50%, -50%); */
}

.video-control-control button:nth-child(3) {
    position: absolute;
    left: 20%;
    /* transform: translate(-50%, -50%); */
}

.video-control-control button:nth-child(4) {
    position: absolute;
    left: 80%;
    /* transform: translate(-50%, -50%); */
}

.video-control-control button:nth-child(5) {
    position: absolute;
    top: 80%;
    /* transform: translate(-50%, -50%); */
}

.camera-control-control {
    position: absolute;
    display: inline-block;
    right: 65px;
    bottom: 10px;
    width: 150px;
    height: 25px;
    border-radius: 10px;
    border: none;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    color: black;
    z-index: 999999999;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1.5rem;
    overflow: hidden;
}

.camera-control-control button {
    border: 0;
    background-color: transparent;
    color: black;
    font-size: 1.25rem;
}

@media screen and (max-width: 500px) {
    .video-control-meet iframe {
        width: 98vw;
    }
    .video-control-control {
        width: 25px;
        height: 25px;
        left: 5px;
        bottom: 5px;
        /* backdrop-filter: blur(20px); */
    }
    .video-control-control button {
        font-size: 0.9rem;
    }
    .camera-control-control {
        font-size: 1.25rem;
    }
}

/* @media screen and (min-height: 350px) and (min-width: 750px) {
    .video-control-meet iframe {
        width: 98vw;
    }
    .video-control-control {
        width: 30px;
        height: 30px;
        right: 10px;
        bottom: 10px;
        backdrop-filter: blur(20px);
    }
    .video-control-control button {
        font-size: 0.9rem;
    }
    .camera-control-control {
        font-size: 1.25rem;
        left: 10px;
        bottom: 10px;
        width: 50px;
        font-size: 1.15rem;
    }
    .camera-control-control button {
        font-size: 1rem;
    }
} */