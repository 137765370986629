.navbar {
    display: flex;
    width: 100%;
    max-height: 10vh;
    align-items: center;
    justify-content: space-between;
    padding: 0px 1vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.25);
    overflow-y: hidden;
}

#navbar-logo-link {
    width: 50%;
}


.navbar-logo {
    width: 100%;
    padding: 15px;
    max-width: max-content;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 1rem;
    color: var(--secondary-color);
}

.navbar-logo span:nth-child(3) {
    display: none;
}

.navbar-logo > img {
    height: 7vh;
    padding: 1vh 0;
}

.navlogo-text {
    color: black;
    margin-top: 8px;
    font-size: larger;
    width: max-content;
}

.navbar > ul {
    width: 40%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1.2rem;
    font-weight: 500;
}

.navbar > ul > li {
    transition: all .5s ease-in-out;
}

.navbar > ul > li:hover {
    color: var(--supplementary-color);
    transform: scale(1.1);
}

.navbar > ul > button {
    padding: 8px 15px;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: center;
    background-color: transparent;
    /* color: var(--tertiary-color); */
    color: black;
    border: 0;
    border-radius: 10px;
    transition: all .5s ease-in-out;
    font-family: 'Rubik', sans-serif;
}

.navbar > ul > button:hover {
    transform: scale(0.9);
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.menu-icons {
    display: none;
}

.ham-menu {
    display: none;
    width: 50%;
    height: 200px;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    position: fixed;
    right: 2.5vw;
    top: 8vh;
    z-index: 98;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1.15rem;
    font-weight: 500;
    border-radius: 20px;
}


@media screen and (max-width: 768px) {
    .navbar {
        padding: 0 2.5vh;
    }
    #navbar-logo-link {
        width: 80%;
    }
    .navbar-logo {
        width: 100%;
    }
    .navbar-logo span:nth-child(2) {
        display: none;
    }
    .navbar-logo span:nth-child(3) {
        display: inline-block;
        font-size: 1rem;
    }
    .navbar > ul {
        display: none;
    }
    .menu-icons {
        display: inline-block;
        font-size: 1.8rem;
    }
    .ham-menu {
        display: flex;
    }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
    #navbar-logo-link {
        width: 80%;
    }
    .navbar-logo {
        width: 100%;
    }
    .navbar-logo span:nth-child(2) {
        display: none;
    }
    .navbar-logo span:nth-child(3) {
        display: inline-block;
        font-size: 1rem;
    }
}
