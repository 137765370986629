.mod-dash-container {
  width: 100vw;
  height: 100vh;
  background-color: var(--primary-color);
  color: var(--tertiary-color);
  flex-direction: row;
  justify-content: space-between;
}

.mod-dash-right {
  /* border-left: 2px solid var(--secondary-color); */
  width: 90vw;
  height: 100%;
}