.flex-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_container {
  width: 100%;
  padding: 10px;
  flex-direction: column;
}

.top {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.aligned {
  width: 20%;
  height: 130px;
  flex-direction: column;
  justify-content: space-between;
}

.footer_logo {
  height: 2rem !important;
  border-radius: 20px;
  object-fit: scale-down;
}

.footer_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.logodiv {
  width: 100%;
  justify-content: space-evenly;
  font-size: 2rem;
}

.logodiv > *:hover {
  cursor: pointer;
  color: var(--secondary-color);
}

.sign-in-footer {
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: center;
  color: #fff;
  position: absolute;
  z-index: 1;
}

/* .aligned > h2 {
   margin-bottom: 20px; 
} */

@media screen and (max-width: 800px) {
  .top {
    flex-direction: column;
  }
  .aligned {
    width: 80%;
    margin: 20px 0;
    height: 130px;
  }
  .aligned > h2 {
    margin-bottom: 10px;
  }
}