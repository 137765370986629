/* Overlay for the popup */
.logout-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup container */
  .logout-popup {
    background: #ffffff; /* White background */
    border-radius: 8px;
    padding: 20px 30px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    width: 300px;
  }
  
  /* Logout message */
  .logout-message {
    font-size: 18px;
    font-weight: 500;
    color: #333; /* Darker text for readability */
    margin-bottom: 20px;
  }
  
  /* Buttons container */
  .logout-buttons {
    display: flex;
    justify-content: space-evenly;
  }
  
  /* Individual buttons */
  .logout-button {
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  /* Confirm button */
  .logout-button.confirm {
    background-color: var(--slate-color); /* Red for danger */
  }
  
  .logout-button.confirm:hover {
    background-color: var(--aqua-color); /* Darker red on hover */
  }
  
  /* Cancel button */
  .logout-button.cancel {
    background-color: var(--slate-color); /* Green for cancel */
  }
  
  .logout-button.cancel:hover {
    background-color: var(--aqua-color); /* Darker green on hover */
  }
  