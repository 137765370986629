.utils {
  display: flex;
  flex-direction: row;
  margin: 0.5rem 1rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
}

.battery-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.battery {
  position: relative;
  width: 40px;
  height: 20px;
  border: 2px solid #333;
  border-radius: 5px;
  background-color: #f0f0f0;
  z-index: -1;
}

.no-battery {
  position: absolute;
  right: 35%;  /* Position it slightly to the left */
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../icons/battery-disconnected.png'); /* Update this path to your Wi-Fi icon */
  z-index: 2;
}

.battery-fill {
  position:absolute;
  left:0;
  height: 100%;
  transition: width 0.5s ease;
  z-index: 0;
}

.battery-percentage {
  font-size: 50%;
  color: rgb(0, 0, 0);
  position: absolute;
  width: max-content;
  top:50;
  left:50;
  z-index: 1;
}

.battery::after {
  content: '';
  position: absolute;
  top: 25%; /* Center it vertically inside battery */
  right: -5px; /* Adjust the distance from the battery */
  width: 8px;  /* Width of the battery cap */
  height: 50%; /* Height of the battery cap relative to battery height */
  background-color: #333;
  border-radius: 2px;
  z-index: 1;
}

.indicator-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: background-color 0.5s ease, box-shadow 0.5s ease;
}

/* Glowing effect */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.indicator-button {
  animation: blink 2s infinite;
}


